import { useEffect } from "react";
import "./skills.scss"
import "aos/dist/aos.css"
//import ScrollAnimation from 'react-animate-on-scroll';
//import Bounce from 'react-reveal/Bounce';
//import Zoom from 'react-reveal/Zoom';
//import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
//import imagen from "../../assets/lampara.jpg"

export default function Skills({AOS}) {
    

    useEffect(() => {    
        if (AOS) {
            AOS.refresh();
          }
    });

    
    

    return (
        
        <div className="skills" id="habilidades">
            <Fade>

                <h1  className="titulo" >Habilidades</h1>
                
                
                <div className="barra" >
                <span className="habilidad1"><h3>Java</h3></span><h1>90%</h1>
                </div>
                <div className="barra">
                    <span className="habilidad2" ><h3>ReactJS</h3></span><h1>80%</h1>
                </div>
                <div className="barra">
                    <span className="habilidad3"><h3>Python</h3></span><h1>70%</h1>
                </div>
                <div className="barra">
                    <span className="habilidad4"><h3>CSS</h3></span><h1>65%</h1>
                </div>
                <div className="barra">
                    <span className="habilidad5"><h3>HTML</h3></span><h1>60%</h1>
                </div>
                <div className="barra">
                    <span className="habilidad6" ><h3>Ingles</h3></span><h1>55%</h1>
                </div>

                {/*<img className="foto" src={imagen} alt=''/>*/}

                <div className="cont">
                    <h3 className="subTitulo">Además de estas aptitudes, tengo conocimientos en distintas bases de datos.</h3>
                    <h3 className="subTitulo2">Como por ejemplo en: MongoDB, MSSQL, Cassandra, Redis y Neo4j.</h3>
                </div>
            </Fade>
        </div>

    )
}
