import React, { useState } from "react";
import "./contacto.scss";
import manos from "../../assets/shake.svg"
import linkedin from "../../assets/AnimacionesJson/linkedin.svg"
import correo from "../../assets/AnimacionesJson/mail.svg"
import { Form, Formik, Field, ErrorMessage } from "formik";
//import ReactBodymovin from 'react-bodymovin';
import { Phone } from "@material-ui/icons";
import Lottie from "react-lottie";
import git from "../../assets/AnimacionesJson/github3.json"
//import correoMov from "../../assets/AnimacionesJson/mail.json"
import correoMovg from "../../assets/AnimacionesJson/correo8.gif"
import lin from "../../assets/AnimacionesJson/linkedin4.gif"
import github from "../../assets/AnimacionesJson/github.svg"


export default function Contacto() {



  const[estadoGit,setEstadoGit]=useState(false);
  const[estadoCorreo,setEstadoCorreo]=useState(false);
  const[estadoLink,setEstadoLink]=useState(false);
  const [message, setMessage] = useState(false);
  
  const enviarMail = async (correo, mensaje) => {
    var data = {mail:correo ,
                cuerpo:mensaje };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    };
    await fetch(`https://email-backend-nico.herokuapp.com/send-email`,requestOptions)
    .then(res => res.json())
    .catch(e=> console.log(e))
  }
 

  const cambiarEstadoGit = () => {
    setEstadoGit(true);
  }

  const cambiarEstadoGitOut = () => {
    setEstadoGit(false);
  }

  const cambiarEstadoCorreo = () => {
    setEstadoCorreo(true);
  }

  const cambiarEstadoCorreoOut = () => {
    setEstadoCorreo(false);
  }

  const cambiarEstadoLink = () => {
    setEstadoLink(true);
  }

  const cambiarEstadoLinkOut = () => {
    setEstadoLink(false);
  }

  return (
    <div className="contact" id="contacto">
      <div className="left">
        <img src={manos} alt="" />
      </div>
      <div className="right">
        <h2>Contáctame!</h2>
        <Formik
          initialValues={{
            mail:'',
            mensaje:''
          }}
          validate= {(valores) => {
            const errores = {};

            if(!valores.mensaje){
              errores.mensaje = 'Por favor ingresa un mensaje.'
            }

            if(!valores.mail){
              errores.mail= 'Por favor ingresa un mail válido.'
            }else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(valores.mail)){
              errores.mail = 'El correo debe contener arroba y un dominio (outlook.com) '
            }

            return errores;
          }}
          onSubmit={(valores, {resetForm}) => {
            resetForm();
            setMessage(true);
            setTimeout(() => setMessage(false), 5000);
            enviarMail(valores.mail , valores.mensaje);
          }}
        >
          {({errors}) => (
            <Form>
              <div className="E-mail">
                <label htmlFor="mail">E-mail</label>
                <Field 
                  type="email" 
                  id="mail" 
                  name="mail" 
                  placeholder="John@ejemplo.com" 
                />
                <ErrorMessage name="mail" component= {() => (
                  <label className="error">{errors.mail}</label>
                )}/>
              </div>
              <div className="mensaje2">
              <div className="mensaje">
                <label htmlFor="mensaje">Mensaje</label>
                <Field as="textarea" 
                  placeholder="Ingrese el texto aquí." 
                  id="mensaje" 
                  name="mensaje"
                  
                />
                <ErrorMessage name="mensaje" component= {() => (
                  <label className="error" >{errors.mensaje}</label>
                )}/>
              </div>
              </div>
              <button type="submit">Enviar</button>
              <span className={message ? 'alert-shown' : 'alert-hidden'}>Gracias! respondere lo antes posible.</span>
            

            
              <div id="redes-sociales" className="redes">
              <ul className="social"> 
              <li> 
                  <a href="https://www.linkedin.com/in/nicol%C3%A1s-franco-dominguez-72a770218" target="_blank" rel='noreferrer' onMouseOver={cambiarEstadoLink} onMouseOut={cambiarEstadoLinkOut}>
                    
                   {!estadoLink && <img src={linkedin} alt=''/>}
                   {estadoLink &&
                   <div>
                   <img src={lin} alt='' className='correo22'/>
                    {/*<Lottie 
                     width={43}
                     height={40}
                     
                      options={{
                        loop: true , 
                        autoplay:true , 
                        animationData: lin,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice"
                        }
                      }}
                        
                    />*/ }
                    </div>
                    }
                  </a>
                </li>
                <li> 
                
                  <a href="https://github.com/DominguezNico" target="_blank" rel='noreferrer' onMouseOver={cambiarEstadoGit} onMouseOut={cambiarEstadoGitOut}>
                   {!estadoGit && <img src={github} alt=''/>}
                   {estadoGit &&
                    <Lottie 
                     width={40}
                     height={40}
                     border={20}
                     
                      //onMouseOver={}
                      options={{
                        autoplay:true , 
                        animationData: git,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice"
                        }
                      }}
                        
                    />
                    }
                  </a>
                </li>
                <li>
                  <a href="mailto:domingueznicoo@hotmail.com" target="_blank" rel='noreferrer' onMouseOver={cambiarEstadoCorreo} onMouseOut={cambiarEstadoCorreoOut} >
                    
                   {!estadoCorreo && <img src={correo} alt='' className='correo2'/>}
                   {estadoCorreo &&
                    <div>
                    <img src={correoMovg} alt='' className='correo22'/>
                    {/*
                    <Lottie 
                      width={40}
                      height={40}

                        options={{
                          
                          animationData: correoMov,
                          renderer: 'svg',
                          loop: false,
                          autoplay: true,
                          autoloadSegments: false,
                          rendererSettings: {
                            preserveAspectRatio:"xMinYMax"
                          }
                        }}
                      />*/}
                    </div>
                    

                    }
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <ul className="tel">
                <li className="centrar">
                <Phone className="telefonoIcon" />
                <span className="numero">11 6409-5966</span>
                </li>
              </ul>
            </div>
            </Form>
            
          )}

          
        </Formik>

            


      </div>
    </div>
  );
}