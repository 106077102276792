import React, { useState,useEffect } from "react";
import BarraInicio from "./componentes/inicio/barraInicio.jsx";
import Intro from "./componentes/introduccion/intro.jsx";
import Portfolio from "./componentes/portfolio/portfolio.jsx";
import Skills from "./componentes/skills/Skills.jsx"
import Contacto from "./componentes/contacto/contacto.jsx";
import Menu from "./componentes/menu/menu.jsx"
import AcercaDe from "./componentes/acerca_de/AcercaDe.jsx"
//import Testimonios from "./componentes/testimonios/testimonios"
//import Trabajos from "./componentes/trabajos/trabajos.jsx";
import "./app.scss"
/*
function useOnScreen(options) {
  const ref = React.useRef();
  const [visible,setVisible] = React.useState(false);


  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
    setVisible(entry.isIntersecting);
    }, options)

    if(ref.current){
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current){
        observe.unobserve(ref.current);
      }
    }

  }, [ref,options])

  return [ref,visible]

}*/



function App() {
  const [menuOpen,setMenuOpen] = useState(false)

  //const [ref,visible] = useOnScreen({rootMargin: '-300px'})
  let AOS;
  useEffect(() => {
      const AOS = require("aos");
      AOS.init({
      once: true,
      });
  }, []);

  useEffect(() => {    
    if (AOS) {
        AOS.refresh();
      }
});






  return (
  <div className="app">
    <BarraInicio menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
    <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
    <div className="secciones" data-aos="fade-up">
      <Intro/>
      <AcercaDe/>
      <Skills AOS={AOS}/>
      
      <Portfolio/>
      {/*<Trabajos/>*/}
      {/*<Testimonios/>*/}
      <Contacto/>    
    
    </div>
  </div>
  );
}

export default App;
