import { useEffect, useState } from "react";
import PortfolioList from "../portfolioList/portfolioList";
import './portfolio.scss'
import Lottie from "react-lottie";
/* import mouse from '../../assets/AnimacionesJson/mouse_click.json';
import click1 from '../../assets/AnimacionesJson/click1.json';
import click2 from '../../assets/AnimacionesJson/click2.json';
import click3 from '../../assets/AnimacionesJson/click3.json';
import click4 from '../../assets/AnimacionesJson/click4.json'; */
import pulse from '../../assets/AnimacionesJson/pulse.json';

import {
  featuredPortfolio,
  webPortfolio,
 // mobilePortfolio,
  algortimos_Api
} from "../../datosACargar";



export default function Portfolio() {
  const [selected, setSelected] = useState("featured");
  const [data, setData] = useState([]);
  const list = [
    {
      id: "featured",
      title: "Todos",
    },
    {
      id: "web",
      title: "Web App",
    },
    {
      id: "api",
      title: "APIS/Algoritmos",
    }
    /*PARA AGREGAR A LA LISTA:
    ,
    {
      id: "mobile",
      title: "Mobile App",
    }
    , 
    {
      id: "content",
      title: "Content",
    } Cada uno de estos me permite agregar una categoria en la navBar*/

  ];
  
  useEffect(() => {
    switch (selected) {
      case "featured":
        setData(featuredPortfolio);
        break;
      case "web":
        setData(webPortfolio);
        break;
      /*case "mobile":
        setData(mobilePortfolio);
        break;*/
     case "api":
        setData(algortimos_Api);
        break;/*
      case "content":
        setData(contentPortfolio);
        break;*/
      default:
        setData(featuredPortfolio);
    }
  }, [selected]);



  return (
    <div className='portfolio' id='portafolio'>
      <h1>Portfolio</h1>
      <ul>
        {list.map((item) => (
          <PortfolioList
            title={item.title}
            active={selected === item.id}
            setSelected={setSelected}
            id={item.id}
          />
        ))}
      </ul>
      <div className="container">
        {data.map((d) => 
        (
          
          <div className="item" href="portafolio">
          <img 
            src={require(`${d.img}`).default}
            alt=''
            />
            <div className="mouse">
              <Lottie 
                width={100}
                height={60}
                
                //onMouseOver={}
                options={{
                  loop: true , 
                  autoplay:true , 
                  animationData: pulse,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
                          
              />
            </div>
           
          <h3>
            <span className="presentacionApp" >
              {d.title}
            </span>
            <h2>
                {d.sub}
            </h2>
            <a href={d.url} target="_blank" rel='noreferrer' className="limpio">{d.tipo}</a>
          </h3>
          </div>
          
        ))}
      </div>
     
    </div>
  );
}