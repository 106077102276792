import React from './menu.scss'
import { Phone, Mail } from "@material-ui/icons";

export default function Menu({menuOpen,setMenuOpen}) {
    return (
        <div className={"menu " + (menuOpen && "active")}>
        <div className='prueba2'>
                <ul>
                    <li>
                    <Phone className="icon"/>
                    <span>11 6409-5966</span>
                    </li>
                    <li>
                    <Mail className="icon"/>
                    <span>domingueznicoo@hotmail.com</span>
                    </li>
                </ul>
            </div>
            
            <div className='prueba'>
                <ul>
                    <li onClick={()=> setMenuOpen(false)}> {/* {onClick={()=> setMenuOpen(false)}} Poner esto dentro del li si cada vez que hago click en un menu quiero que se cierre*/} 
                        <a href="#intro">Inicio</a>
                    </li>
                    <li onClick={()=> setMenuOpen(false)}> 
                        <a href="#acerca">Presentación</a>
                    </li>
                    <li onClick={()=> setMenuOpen(false)}> 
                        <a href="#habilidades">Habilidades</a>
                    </li>
                    <li onClick={()=> setMenuOpen(false)}> 
                        <a href="#portafolio">Portfolio</a>
                    </li>
                    <li onClick={()=> setMenuOpen(false)}> 
                        <a href="#contacto">Contacto</a>
                    </li>                
                </ul>
            </div>
         
            
           
        </div>
    )
}
