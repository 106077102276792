

export const featuredPortfolio = [
    {
      id: 1,
      title: "Marvin-PC Web App",
      sub:"Aplicación web que te recomienda una computadora dependiendo de tus preferencias. El backend esta desarrollado con express y MongoDB.",
      img: "./Marvin-pc.png",
      tipo: "Ver App",
      url:"https://marvin-pc.com"
    },
    {
      id: 2,
      title: "Sistema de fútbol",
      sub:"Aplicación web que te permite organizar torneos de fútbol mediante diferentes perfiles (Jugador, Representante y Admin) cada uno con una interfaz diferente.",
      img: "./Futbol.png",
      tipo: "Ver código",
      url:"https://github.com/DominguezNico/TP_APIS_FRONT"
    },
    {
      id: 3,
      title: "Password fuerza bruta",
      sub:"Algoritmo de fuerza bruta desarrollado en Java que te permite saber si una password es segura o no.",
      img: "./pasword.png",
      tipo: "Ver código",
      url:"https://github.com/DominguezNico/Password"
    },
    {
      id: 4,
      title: "Persistencia políglota",
      sub:"Aplicación desarrollada en Java para realizar la persistencia de un sistema en distintas bases de datos (MongoDB, ObjectDB, Neo4j, entre otras).",
      img: "./persistencia_pol.png",
      tipo: "Ver código",
      url:"https://github.com/DominguezNico/persistencia_poliglota"
    },
    {
      id: 5,
      title: "API fútbol",
      sub:"API de la aplicación web de fútbol desarrollada en Java mediante Spring y Maven.",
      img: "./API_futbol.png",
      tipo: "Ver código",
      url:"https://github.com/DominguezNico/futbol_back"
    }/*,
    {
      id: 5,
      title: "Hero Web Design",
      img:
        "https://cdn.dribbble.com/users/5031392/screenshots/15467520/media/c36b3b15b25b1e190d081abdbbf947cf.png?compress=1&resize=1200x900",
    },
    {
      id: 6,
      title: "Banking App",
      img:
        "https://cdn.dribbble.com/users/3307260/screenshots/15468444/media/447b4501f7a145b05e11c70199a9c417.jpg?compress=1&resize=1200x900",
    },*/
  ];
  
  export const webPortfolio = [
    {
      id: 1,
      title: "Marvin PC Web App",
      sub:"Aplicación web que te recomienda una computadora dependiendo de tus preferencias. El backend esta desarrollado con express y MongoDB.",
      img: "./Marvin-pc.png",
      tipo: "Ver App",
      url:"https://marvin-pc.com"
    },
    {
      id: 2,
      title: "Sistema de fútbol",
      sub:"Aplicación web que te permite organizar torneos de fútbol mediante diferentes perfiles (Jugador, Representante y Admin) cada uno con una interfaz diferente.",
      img: "./Futbol.png",
      tipo: "Ver código",
      url:"https://github.com/DominguezNico/TP_APIS_FRONT"
    }
  ];
  
  /*
  export const mobilePortfolio = [
    {
      id: 1,
      title: "Mobile Social Media App",
      img:
        "https://cdn.dribbble.com/users/2597268/screenshots/15468893/media/44313553d73ba41580f1df69749bba28.jpg?compress=1&resize=1200x900",
    },
    {
      id: 2,
      title: "Mobile Rampa UI Design",
      img:
        "https://cdn.dribbble.com/users/5920881/screenshots/15463142/media/b5a460223798bd85d835710806e2f3dd.png?compress=1&resize=1200x900",
    },
    {
      id: 3,
      title: "Mobile E-commerce Design",
      img:
        "https://cdn.dribbble.com/users/1998175/screenshots/15459384/media/48ac2b43ebe81ba0866afea1383cc939.png?compress=1&resize=1200x900",
    },
    {
      id: 4,
      title: "Mobile Relax App",
      img:
        "https://cdn.dribbble.com/users/2083704/screenshots/15468619/media/cd958306c7a772449e1ac23bd65ce506.png?compress=1&resize=1200x900",
    },
    {
      id: 5,
      title: "Mobile Hero Design",
      img:
        "https://cdn.dribbble.com/users/26642/screenshots/15462545/media/1a202ef5c8338b6f78634e6edc1ba350.png?compress=1&resize=1200x900",
    },
    {
      id: 6,
      title: "Mobile Banking App",
      img:
        "https://cdn.dribbble.com/users/1387827/screenshots/15466426/media/deb2dca6762cd3610321c98bfccb0b72.png?compress=1&resize=1200x900",
    }
  ];*/


  export const algortimos_Api = [
  {
    id: 3,
    title: "Password fuerza bruta",
    sub:"Algoritmo de fuerza bruta desarrollado en Java que te permite saber si una password es segura o no.",
    img: "./pasword.png",
    tipo: "Ver código",
    url:"https://github.com/DominguezNico/Password"
  },
  {
    id: 4,
    title: "Persistencia políglota",
    sub:"Aplicación desarrollada en Java para realizar la persistencia de un sistema en distintas bases de datos (MongoDB, ObjectDB, Neo4J, entre otras).",
    img: "./persistencia_pol.png",
    tipo: "Ver código",
    url:"https://github.com/DominguezNico/persistencia_poliglota"
  },
  {
    id: 5,
    title: "API fútbol",
    sub:"Aplicación web que te permite organizar torneos de fútbol mediante diferentes perfiles (Jugador, Representante y Admin) cada uno con una interfaz diferente.",
    img: "./API_futbol.png",
    tipo: "Ver código",
    url:"https://github.com/DominguezNico/futbol_back"
  }
];