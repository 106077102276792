import vacio from '../../assets/void.png';
import "./barraInicio.scss";


export default function BarraInicio({menuOpen,setMenuOpen}) {
  return (
    <div className={"barraInicio "+(menuOpen && "active")}> {/*Si menu open es verdadero entonces a barra inicio se le agrega el valor active de barraInicio.css*/}
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="logo">
            <img src={vacio} alt='' className='vacio'/>
            <span className='nombre' >ND</span>
          </a>
      
          <div className="itemContainer">
            
          </div>
        </div>

        <span className='cv'>CV</span>

        <div className="right">
          <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
}