import './acercaDe.scss'
import Tilt from 'react-tilt'
import boy from '../../assets/boy.svg'
import api from '../../assets/api.png'
import user from '../../assets/user-check.svg'
import web from '../../assets/laptop.png'
export default function AcercaDe() {
    return (
        <div className='fondo' id="acerca">
            <Tilt className="card" options={{ max : 2 }}>
                <div className='centrarC'>
                    <div className='circulo'>
                        <img className='foto' src={boy} alt=''/>
                    </div>
                </div>
                <hr className='linea'/>
                <p className='parrafo'>Soy un joven programador a quien le encanta aprender. Disfruto los desafíos y realizar proyectos que requieran trabajar fuera de mi zona de confort. </p>
                <p className='parrafo'>En el último tiempo, me dedique al desarrollo web, creando desde sitios simples a sistemas complejos, permitiéndome comprender nuevas tecnologías como React, y utilizar distintas bases de datos para el desarrollo de APIS.</p>
                <p className='parrafo'>Estudio ingeniería informática en la UADE, y me recibí de analista informático en la misma institución.</p>
                
                <div className='contenedor'>
                    <div className='contenedorImg'>
                        <img src={web} className='web' alt=''/>
                        <p>Desarrollo web</p>
                    </div>
                    <div className='contenedorImg'>
                        <img src={api} className='api' alt=''/>
                        <p className='apiP'>Desarrollo de APIS</p>
                    </div>
                    <div className='contenedorImg'>
                        <img src={user} className='req' alt=''/>
                        <p>Análisis de requerimientos</p>
                    </div>
                    
                </div>
                
                
                

              
                
               
            </Tilt>
            
        </div>
    )
}
